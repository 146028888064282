import React, {useState, useEffect} from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  Spinner,
  InputGroup,
  Form,
  Modal,
} from "react-bootstrap"
import PageTitle from "../../components/PageTitle"
import Table from "../../components/Table"
import callApi from "../../utils/apiCaller"
import {useNavigate, useSearchParams} from "react-router-dom"
import ReactPaginate from "react-paginate"
import swal from "sweetalert"
import {useDispatch, useSelector} from "react-redux"
import {setServicePlacesPageNumber,setServicePlacesLocationPageSize} from "../../redux/actions"
import {allLocationColumn} from "./components/ServicePlacesColumns"
import {FormInput} from "../../components"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import FilterServicePlaces from "./components/FilterServicePlaces"
import {Scale} from "chart.js"
import _ from "lodash"
import LoadingButton from "../../components/LoadingButton"
import NewTable from "../../components/NewTable"

const pl = ["airport", "community", "corporate"]
const places = pl.map((location) => {
  return {
    label: location,
  }
})
const Locations = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  //States
  const [data, setData] = React.useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [pageCount, setpageCount] = useState(0)
  const [limit, setLimit] = useState(50)
  const [filter, setFilter] = useState({})
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [searchString, setSearchString] = useState("")
  const [searchInput, setSearchInput] = useState(
    sessionStorage.getItem("SERICE_LOCATIONS_SEARCH_TEXT") || "" )  
    const [loading, setLoading] = useState(false)
  const [isModalVisible, setModal] = useState(false)
  const [newApartment, setNewApartment] = useState(null)
  const [locationId, setlocationId] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [address, setadress] = useState(null)
  const [type, settype] = useState(null)
  const [lat, setlat] = useState(null)
  const [lng, setlng] = useState(null)
  const [locationListByCity, setLocationListByCity] = React.useState([])
  const [cityId, setCityId] = React.useState(null)

  const [location, setLocation] = React.useState([])
  const [cities, setCity] = React.useState([])
  const [apartments, setApartments] = React.useState([])
  const [apartmentsFromApi, setApartmentsFromApi] = React.useState([])
  const [addingAddress, setAddingAddress] = useState(null)
  const [addingBasements, setAddingBasements] = useState([])
  const [addingPlacetype, setAddingPlacetype] = useState([])
  const [addingLattitude, setAddingLattitude] = useState(null)
  const [addingLongitude, setAddingLongitude] = useState(null)
  const [addingApartment, setAddingApartment] = useState(null)
  const [showFilter, setShowFilter] = useState(false)
  const [filters, setFilters] = useState(null)
  const [name, setName] = useState(null)
  const [polygon_coordinates, setPolygonCoordinates] = useState(null)
  const [addingLocation, setAddingLocation] = useState(false)
  const [doingWork, setDoingWork] = useState(false)

  const {servicePlacesPageNumber} = useSelector((state) => ({
    servicePlacesPageNumber: state.AppState.servicePlacesPageNumber,
  }))
  
  const { servicePlacesLocationPageSize} = useSelector((state) => ({
    servicePlacesLocationPageSize: state.AppState. servicePlacesLocationPageSize,
  }))
  React.useEffect(() => {
    let s_text = sessionStorage.getItem("SERICE_LOCATIONS_SEARCH_TEXT")
    if(!_.isEmpty(s_text)){
      console.log('s_text', s_text)
    handleAllServicePlaces(1, { search: s_text});
      
    } else
      // handleAllServicePlaces(servicePlacesPageNumber);
      fetchData(servicePlacesPageNumber)
  }, [searchString, loading]);

  const handlePageClick = async (data) => {
    setData([])
    let locCurrentPage = data.selected
    locCurrentPage = locCurrentPage + 1
    dispatch(setServicePlacesPageNumber(locCurrentPage))
    fetchData(locCurrentPage);
    // handleAllServicePlaces(locCurrentPage)
  }

  // const optionHandler = (e) => {
  //   setLimit(e.target.value)
  // }
  const optionHandler = (e) => {
    const selectedLimit = parseInt(e.target.value, 10);
    dispatch(setServicePlacesLocationPageSize(selectedLimit)); 
    dispatch(setServicePlacesPageNumber(1));
  fetchData(1, { ...filters, pageSize: selectedLimit }); 
  };
  const removeFilters = () => {
    setShowFilter(false)
    fetchData(1)
  }
  const applyAllFilters = (filterObject) => {
    setIsFilterApplied(true)
    setFilters(filterObject)
    fetchData(1, filterObject)
  }
  const fetchData = (num, filterType) => {
    let data = {
      pageNum: num,
      pageSize:  servicePlacesLocationPageSize,
    }
    if (searchString && searchString != "") {
      data.search = searchString
    }
    if (filterType) {
      data = {
        ...data,
        ...filterType,
      }
    }
    setIsLoading(true)
    callApi("apartments/getall", "POST", data).then((res) => {
      if (res.data) {
        setData(res.data)
        setpageCount(res.totalPages || 1)
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    })
  }
  const filterBookings = (filter, locCurrentPage) => {
    setData([])
    setFilter(filter)
    setIsLoading(true)
    callApi(`apartments/getall`, "post", {
      pageNum: locCurrentPage || 1,
      pageSize:  servicePlacesLocationPageSize,
      ...filter,
    }).then((res) => {
      if (res.data) {
        setIsFilterApplied(true)
        setData(res.data)
        setIsLoading(false)
        setpageCount(res.totalpages || 1)
      } else {
        setIsLoading(false)
      }
    })
  }
  const handleAllServicePlaces = (num, filterType) => {
    let data = {
      pageNum: num,
      pageSize:  servicePlacesLocationPageSize,
    }
    if (searchString && searchString != "") {
      data.search = searchString
    }
    if (filterType) {
      data = {
        ...data,
        ...filterType,
      }
    }
    if(filterType?.search){
      console.log('first', filterType.search)
      sessionStorage.setItem("SERICE_LOCATIONS_SEARCH_TEXT", filterType.search)
    }
    callApi(`locations/getall`, "post", data).then((res) => {
      if (res.success) {
        setData(res.data)
        setpageCount(res.totalpages || 1)
      } else {
        setData(res.data)
      }
    })
  }

  let newColumns = [...allLocationColumn]
  newColumns.unshift({
    Header: "SNo",
    accessor: "serialNo",
    Cell: ({row}) => {
      return (
        <span>{ servicePlacesLocationPageSize * (servicePlacesPageNumber - 1) + row.index + 1}</span>
      )
    },
  })
  useEffect(() => {
    if (searchParams.get("id")) {
      setAddingApartment(searchParams.get("id"))
    }
  }, [])

  const getLocationListByCity = async (value) => {
    callApi(`location/${value}`, `get`).then((res) => {
      if (res.success) {
        const list_b = res.data.map((location) => {
          return {
            label: location.name,
            value: location.name,
            _id: location._id,
          }
        })
        setLocationListByCity(list_b)
      }
    })
  }
  const getApartments = async (value) => {
    const location = value.map((i) => {
      return i._id
    })

    callApi(`apartment`, `POST`, {locationId: location}).then((res) => {
      if (res.success) {
        const list_b = res.data.map((location) => {
          return {
            _id: location._id,
          }
        })
        setApartmentsFromApi(list_b)
      }
    })
  }

  const checkAll = () => {
    if (_.isEmpty(lat) || _.isEmpty(lng)) {
      swal("Please fill lat and lng")
      return false
    }
    if (_.isEmpty(name)) {
      return false
    } else {
      return true
    }
  }

  const addLocation = () => {
    if (checkAll()) {
      let locationdata = {
        cityId,
        lat,
        lng,
        name,
        polygon_coordinates,
      }

      console.log("locationdata", locationdata)
      setAddingLocation(true)
      setDoingWork(true)
      callApi(`locations/create`, "POST", {...locationdata}).then((res) => {
        if (res.success) {
          setDoingWork(false)
          setlat(null)
          setlng(null)
          setName(null)
          setCityId(null)
          setPolygonCoordinates(null)
          setIsLoading(true)
          setModal(false)
          handleAllServicePlaces()
          swal("Success", "New Location Added", "success")
          setAddingLocation(false)
        } else {
          setAddingLocation(false)
          setModal(false)
          swal("Error", res.message, "error")
        }
      })
    } else {
      setDoingWork(false)
      swal(
        "Fill Mandatory fields!",
        "please fill all the mandatory fields to proceed.",
        "warning"
      )
    }
  }

  useEffect(() => {
    // getCities()
  }, [])
  const getCities = async () => {
    callApi(`city`, `get`).then((res) => {
      if (res.success) {
        setCity(res.data)
      }
    })
  }
  return (
    <>
      <div style={{marginLeft: 26}}>
        <PageTitle
          breadCrumbItems={[
            {
              label: "Service Places",
              path: "/apps/ecommerce/customers",
              active: true,
            },
          ]}
          title={"Locations"}
        />
      </div>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body>
              <Col className="mb-3">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginRight: 20,
                      }}
                    >
                      <span className="d-flex align-items-center">
                        <InputGroup
                          style={{
                            display: "flex",
                            border: "1px solid #00000050",
                            borderRadius: 4,
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Form.Control
                            placeholder="Search...."
                            aria-label="Search...."
                            aria-describedby="basic-addon2"
                            style={{border: 0}}
                            onChange={(e) => {
                              setSearchInput(e.target.value)
                            }}
                            id="search"
                            value={searchInput}
                            onKeyDown={(event) =>
                              event.key === "Enter" &&
                              handleAllServicePlaces(1, {search: searchInput})
                            }
                          ></Form.Control>
                          <div
                            style={{
                              cursor: "pointer",
                              marginRight: 10,
                              paddingLeft: 10,
                            }}
                            onClick={() => {
                              setSearchInput("")
                              setLoading(!loading)
                              handleAllServicePlaces(1, {search: ""})
                            }}
                          >
                            <i className="mdi mr-1  dripicons-cross"></i>
                          </div>

                          <button
                            className="btn btn-block"
                            style={{
                              backgroundColor: "#2A882A",
                              color: "white",
                            }}
                            onClick={() =>
                              handleAllServicePlaces(1, {search: searchInput})
                            }
                          >
                            <i className="mdi mr-1"></i> Search
                          </button>
                        </InputGroup>
                      </span>
                      <button
                        className=" mdi mdi-square-edit-outline action-icon btn btn-rounded btn-primary"
                        onClick={() => {
                          setModal(!isModalVisible)
                          getCities();
                        }}
                        disabled={addingLocation ? true : false}
                      >
                        {addingLocation ? "Adding Location" : " Add Location"}
                      </button>
                      {/* {showFilter ? (
                        <Alert
                          variant="warning"
                          onClose={() => removeFilters()}
                          dismissible
                          className=""
                        >
                          Remove Filter
                        </Alert>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={() => setShowFilter(true)}
                        >
                          Add Filter
                        </button>
                      )} */}
                    </div>

                    {showFilter && (
                      <div>
                        <FilterServicePlaces
                          applyAllFilters={applyAllFilters}
                        />
                      </div>
                    )}
                  </Col>
                  <Modal
                    show={isModalVisible}
                    onHide={() => setModal(!isModalVisible)}
                    // size={"lg"}
                  >
                    <Modal.Body>
                      <div className=" mt-2 mb-4">
                        <div className="text-center">
                          <h5> Location Details</h5>
                        </div>
                        <FormInput
                          label="Location Name *"
                          type="text"
                          name="location_name"
                          placeholder="Location Name *"
                          containerClass={"mb-2"}
                          key="location_name"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value)
                          }}
                        />
                        <FormInput
                          label="City"
                          type="select"
                          name="city"
                          placeholder="Enter City"
                          key="city"
                          containerClass={"mb-2"}
                          value={cityId}
                          onChange={(e) => {
                            setCityId(e.target.value)
                          }}
                        >
                          <option value={null} id={null}>
                            ...select...
                          </option>
                          {cities.map((city) => (
                            <option
                              key={city.id}
                              value={city._id}
                              id={city._id}
                            >
                              {city.name}
                            </option>
                          ))}
                        </FormInput>
                        <FormInput
                          label="Latitude *"
                          type="number"
                          name="lat"
                          placeholder="Latitude *"
                          containerClass={"mb-2"}
                          key="lat"
                          value={lat}
                          onChange={(e) => {
                            setlat(e.target.value)
                          }}
                        />
                        <FormInput
                          label="Longitude *"
                          type="number"
                          name="lon"
                          placeholder="Longitude *"
                          containerClass={"mb-2"}
                          key="lon"
                          value={lng}
                          onChange={(e) => {
                            setlng(e.target.value)
                          }}
                        />
                        <Row>
                          <Col>
                            <FormInput
                              label=" Enter polygons"
                              type="textarea"
                              name="polygons"
                              placeholder="Polygons..."
                              defaultValue={data?.polygon?.coordinates}
                              rows="20"
                              containerClass={"mb-3"}
                              value={polygon_coordinates}
                              onChange={(e) => {
                                setPolygonCoordinates(e.target.value)
                              }}
                            />
                          </Col>
                        </Row>
                        <div className="text-center">
                          <LoadingButton
                                        disabled={doingWork ? true : false}
                            className="btn btn-rounded btn-primary mt-3"
                            onClick={() => {
                              addLocation()
                            }}
                          >
                            Add Location
                          </LoadingButton>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>{" "}
                </div>
              </Col>

              {data ? (
                <>
                  {data && (
                    <>
                      <NewTable
                        columns={newColumns}
                        data={data}
                        pageSize={ servicePlacesLocationPageSize}
                        sizePerPageList={[{text:  servicePlacesLocationPageSize, value:  servicePlacesLocationPageSize}]}
                        isSortable
                        theadClass="table-light"
                        searchBoxClass="mt-2 mb-3"
                        onClick={(row) => {
                          navigate({
                            pathname: "/service-places/location-details",
                            search: `?id=${row._id}`,
                          })
                        }}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  <Spinner
                    animation="border"
                    variant="primary"
                    className="d-block mx-auto"
                    size="lg"
                  />
                </>
              )}
              <Row>
                <Col lg={12} className="mt-3">
                  <Row>
                    {/* <Col lg={6}>
                      <div className="d-lg-flex align-items-center text-center pb-1">
                        <label className="me-1">Display :</label>
                        <select
                          onChange={(e) => {
                            optionHandler(e)
                          }}
                          className="form-select d-inline-block w-auto"
                        >
                          <option value={limit}>{limit}</option>
                        </select>
                      </div>
                    </Col> */}
                                              <Col lg={6}>
  <div className="d-lg-flex align-items-center text-center pb-1">
    <label className="me-1">Display :</label>
    <select
      onChange={(e) => {
        optionHandler(e);
      }}
      className="form-select d-inline-block w-auto"
      value={servicePlacesLocationPageSize} 
    >
  
      {[10, 20, 50, 100].map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </select>
  </div>
</Col>
                    <Col lg={6}>
                      <ReactPaginate
                        previousLabel="previous"
                        nextLabel="next"
                        breakLabel="..."
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        onPageChange={(data) => handlePageClick(data)}
                        forcePage={servicePlacesPageNumber - 1}
                        disableInitialCallback
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        activeClassName="active"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

const styles = {
  row: {
    flexDirection: "row",
    display: "flex",
  },
}
export default Locations
