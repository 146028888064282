// @flow
import React from "react"
import {Card, Dropdown} from "react-bootstrap"
import moment from "moment"
import {Link, useNavigate} from "react-router-dom"

// images
import profileImg from "../../../assets/images/users/avatar-1.jpg"

const AgencyBoxAddOn = (props) => {
  const data = props.data
  const navigator = useNavigate()

  return (
    <div className="border-bottom">
      <Dropdown className="float-end" align="end">
        <Dropdown.Toggle
          variant="link"
          className="arrow-none card-drop p-0 shadow-none"
        >
          <i className="mdi mdi-dots-horizontal"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              navigator({
                pathname: "/service-packages/edit-addon",
                search: `id=${data._id}`,
              })
            }}
          >
            Edit Add On
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/* <img
          src={data?.icon}
          className="rounded-circle avatar-lg img-thumbnail"
          alt=""
        /> */}
      <div className="text-start mt-3">
        <h4 className="font-13">Basic Details :</h4>

        <p className="text-muted mb-2 font-13">
          <strong>Add On Name :</strong>
          <span className="ms-2">{data?.name}</span>
        </p>
        <p className="text-muted mb-2 font-13">
          <strong>Per Day Limit :</strong>
          <span className="ms-2">{data?.perDayLimit}</span>
        </p>
        <p className="text-muted mb-1 font-13">
          <strong>Vehicle type :</strong>
          <span className="ms-2 badge bg-primary" style={{fontSize: 12}}>
            {data?.vehicle_type}
          </span>
        </p>
        <p className="text-muted mb-1 font-13">
          <strong>Created date :</strong>
          <span className="ms-2">
            {moment(data?.created_at).format("DD-MM-YY")}
          </span>
        </p>
      </div>
    </div>
  )
}

export default AgencyBoxAddOn
