import _ from "lodash"
import React from "react"
import {Row, Col, Card} from "react-bootstrap"

const PackagePrices = (props) => {
  const data = props.data
  return (
    <Col className="border-bottom">
      <Row>
        <Col xl={6}>
          <div>
            <h4 className="font-15">Prices :</h4>
            {data?.vehicle_type_prices?.map((i, index) => (
              <p key={(i, index)}>
                <i className="mdi mdi-square text-primary"></i> {i.car_type}
                <span className="float-end">₹{i.cost}</span>
              </p>
            ))}
          </div>
        </Col>
        {!_.isEmpty(data?.vehicle_type_prices_special) && (
          <Col xl={6}>
            <div className="chart-widget-list">
              <h4 className="font-15">Special prices :</h4>
              {data?.vehicle_type_prices_special?.map((i, index) => (
                <p key={index}>
                  <i className="mdi mdi-square text-success"></i> {i.car_type}
                  <span className="float-end">₹{i.cost}</span>
                </p>
              ))}
            </div>
          </Col>
        )}
      </Row>
    </Col>
  )
}

export default PackagePrices
