import React, {useEffect, useState, useRef} from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import {Col, Row} from "react-bootstrap"
import moment from "moment"
import callApi from "../../../utils/apiCaller"
import {useNavigate} from "react-router-dom"
import swal from "sweetalert"
import _ from "lodash"
import BootstrapTheme from "@fullcalendar/bootstrap"

const CalendarFull = (props) => {
  const currentSearchParams = new URLSearchParams(window.location.search)
  const date = currentSearchParams.get("date")
  const navigate = useNavigate()
  const calenderRef = useRef(null)
  const [event, setEvent] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getCalenderData(date ? date : moment().format("YYYY-MM-DD"))
  }, [props.calenderData])

  const getCalenderData = (date) => {
    let data = {
      servicebookingId: props.id,
      date: date,
    }
    setLoading(true)
    callApi(`servicebooking/getCalenderDetails`, "POST", data)
      .then((res) => {
        if (res.success) {
          let newDates = res?.data?.map((i) => {
            return {
              id: i?.taskId,
              myId: i?.taskId,
              title: i?.status === "HOLD" ? "Hold" : i?.status,
              start: moment.utc(i.date).format("YYYY-MM-DD"),

              backgroundColor:
                i.status === "Completed"
                  ? "#2a882a"
                  : i.status === "Cleaner did not clean"
                  ? "#d32f2f"
                  : i.status === "Not Attempted"
                  ? "#6300b3"
                  : i.status === "HOLD"
                  ? "#ffb100"
                  : i.status === "Vehicle not available"
                  ? "#0181e3"
                  : i.status === "Week-off"
                  ? "#6d6d6d"
                  : "#fff",
              eventTextColor: i.status === "HOLD" ? "#000" : "#fff",
              textColor: i.status === "HOLD" ? "#000" : "#fff",
              description: i?.status === "HOLD" ? "Hold" : i?.status,
              actionTime: i?.actionTime,
            }
          })
          setLoading(false)
          setEvent(newDates)
        } else {
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleNext = (val) => {
    calenderRef.current.getApi().next()
    let calendarApi = calenderRef.current.getApi()
    let date = calendarApi.getDate()
    getCalenderData(moment(date).format("YYYY-MM-DD"))
    const currentSearchParams = new URLSearchParams(window.location.search)
    currentSearchParams.set("date", moment(date).format("YYYY-MM-DD"))
    const currentUrlWithoutSearch = window.location.href.split("?")[0]
    window.history.replaceState(
      null,
      null,
      `${currentUrlWithoutSearch}?${currentSearchParams.toString()}`
    )
  }

  const handlePrev = (val) => {
    let calendarApi = calenderRef.current.getApi()
    let date = calendarApi.getDate()
    calenderRef.current.getApi().prev()
    getCalenderData(moment(date).subtract(1, "months").format("YYYY-MM-DD"))
    const currentSearchParams = new URLSearchParams(window.location.search)
    currentSearchParams.set(
      "date",
      moment(date).subtract(1, "months").format("YYYY-MM-DD")
    )
    const currentUrlWithoutSearch = window.location.href.split("?")[0]
    window.history.replaceState(
      null,
      null,
      `${currentUrlWithoutSearch}?${currentSearchParams.toString()}`
    )
  }

  const handleToday = (val) => {
    calenderRef.current.getApi().today()
    getCalenderData(moment().format("YYYY-MM-DD"))
    const currentSearchParams = new URLSearchParams(window.location.search)
    currentSearchParams.set("date", moment().format("YYYY-MM-DD"))
    const currentUrlWithoutSearch = window.location.href.split("?")[0]
    window.history.replaceState(
      null,
      null,
      `${currentUrlWithoutSearch}?${currentSearchParams.toString()}`
    )
  }

  const handleDateClick = (arg) => {
    console.log("arg", arg.event)
    if (arg.event.extendedProps.myId) {
      navigate(`/jobs/job-details?id=${arg.event.id}`)
    } else {
      swal("No Job", "No job is assigned on this date", "warning")
    }
  }

  return (
    <>
      <Row>
        <Col md={12}>
          <div id="calendar">
            {loading ? (
              <div className="card-disabled" style={styles.cardDisabled}>
                <div className="card-portlets-loader"></div>
              </div>
            ) : null}
            {event && (
              <FullCalendar
                initialDate={date ? date : moment().format("YYYY-MM-DD")}
                initialView="dayGridMonth"
                plugins={[dayGridPlugin, interactionPlugin]}
                handleWindowResize={true}
                themeSystem="bootstrap"
                buttonText={{
                  today: "Today",
                  month: "Month",
                  prev: "Prev",
                  next: "Next",
                }}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "",
                }}
                editable={true}
                selectable={true}
                droppable={true}
                height={800}
                events={event}
                firstDay={1}
                ref={calenderRef}
                eventClick={handleDateClick}
                customButtons={{
                  prev: {
                    // text: "Prev",
                    // icon: "mdi mdi-eye",
                    click: handlePrev,
                  },
                  next: {
                    // text: "Next",
                    // icon: "mdi mdi-eye",
                    click: handleNext,
                  },
                  today: {
                    text: moment().format("MMMM"),
                    // icon: "mdi mdi-eye",
                    click: handleToday,
                  },
                }}
                eventContent={(arg, createElement) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={styles.dot} />
                        {arg.event.extendedProps.actionTime &&
                          arg.event.extendedProps.description ===
                            "Completed" && (
                            <div style={{fontSize: 12, marginRight: 2}}>
                              {moment(
                                arg.event.extendedProps.actionTime
                              ).format("hh:mm")}
                            </div>
                          )}
                        <div
                          style={{
                            fontSize: 12,
                            color: arg.event.textColor,
                            fontWeight: "bold",
                          }}
                        >
                          {arg.event.title === "Completed"
                            ? "Cleaned"
                            : arg.event.title}
                        </div>
                      </div>
                    </>
                  )
                }}
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default CalendarFull

const styles = {
  cardDisabled: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0.5,
    zIndex: 999,
  },
  dot: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: "#fff",
    marginRight: 4,
  },
}
