import _ from "lodash"
import React, {useEffect, useState} from "react"
import {Badge, Button, Card, Col, Form, Modal, Row} from "react-bootstrap"
import swal from "sweetalert"
import callApi from "../../../../utils/apiCaller"

const BookingDetailsRightCard = (props) => {
  const {data, getData} = props || {}

  const [isModalVisible, setModal] = useState(false)
  const [parkingSlot, setParkingSlot] = useState(null)
  const [editPillar, setEditPillar] = useState(null)
  const [editparking, setEditParking] = useState(null)
  const [showVehicleModal, setShowVehicleModal] = useState(false)
  const [showAddressModal, setShowAddressModal] = useState(false)
  const [vehicleLicenseNo, setVehicleLicenseNo] = useState(
    data?.vehicleId?.license_number
  )
  const [updateAddress, setUpdateAddress] = useState()
  useEffect(() => {
    if (!_.isEmpty(data)) {
      setVehicleLicenseNo(data?.vehicleId?.license_number)
      setUpdateAddress({
        parking_slot: data?.addressId?.parking_slot,
        pillar_no: data?.addressId?.pillar_no,
      })
    }
  }, [data])
  // const handleUpdate = () => {
  //   if (props.data._id) {
  //     const info = {
  //       servicebookingId: props.data._id,
  //       parking_slot: data.parking_slot,
  //       pillar_no: data.pillar_no,
  //     };
  //     console.log("info", info);
  //     callApi("servicebooking/updatebooking", "POST", info).then((res) => {
  //       if (res.success) {
  //         // update swal success
  //         swal("Success", "Updated successfully", "success")

  //           .then(() => {
  //             setModal(false);
  //             props.getData(props.data._id);
  //           })
  //           .catch(swal.noop);
  //       } else {
  //         // update swal error
  //         swal("Error", res.message, "error")

  //           .then(() => {
  //             setModal(false);
  //             props.getData(props.data._id);
  //           })
  //           .catch(swal.noop);
  //       }
  //     });
  //   }
  // };
  const RenderView = ({text, sub_text}) => {
    return (
      <p
        className="mb-1"
        style={{
          display: "flex",
          justifyContent: "",
        }}
      >
        <Col xl={5}>
          <span
            style={{
              color: "#00000090",
              fontSize: "16px",
            }}
          >
            {text}:{" "}
          </span>
        </Col>
        <strong
          style={{
            color: "#2b2d2f",
            fontSize: "15px",
          }}
          className="ms-2 "
        >
          {sub_text}
        </strong>
      </p>
    )
  }

  const updateVehicleLicense = async () => {
    if (data._id) {
      const info = {
        servicebookingId: data._id,
        license_number: vehicleLicenseNo,
      }
      const res = await callApi("servicebooking/updatebooking", "POST", info)
      try {
        if (res?.success) {
          swal({
            title: "Success",
            text: "Vehicle updated successfully",
            icon: "success",
            button: "Ok",
          }).then(() => {
            setShowVehicleModal(false)
            getData(data?._id)
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const submitUpdateAddress = async () => {
    if (data._id) {
      const info = {
        servicebookingId: data._id,
        parking_slot: updateAddress?.parking_slot,
        pillar_no: updateAddress?.pillar_no,
      }
      const res = await callApi("servicebooking/updatebooking", "POST", info)
      try {
        if (res?.success) {
          swal({
            title: "Success",
            text: "Address updated successfully",
            icon: "success",
            button: "Ok",
          }).then(() => {
            setShowAddressModal(false)
            getData(data?._id)
          })
        }
      } catch (error) {}
    }
  }

  return (
    <>
      <Card className="">
        <Card.Body>
          {/* <Dropdown className="float-end" align="end">
            <Dropdown.Toggle
              variant="link"
              className="arrow-none card-drop p-0 shadow-none"
            >
              <i className="mdi mdi-more" style={{}}></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setModal(!isModalVisible);
                }}
              >
                Edit Address
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          {/* <Modal
            show={isModalVisible}
            onHide={() => setModal(!isModalVisible)}
            // size={"lg"}
          >
            <Modal.Body>
              <Col>
                <div className="text-center mt-2 mb-4">
                  <div className="text-center">
                    <h5> Enter parking slot & pillar</h5>
                  </div>
                  <div className="text-center ">
                    <div className="text-start">
                      Enter parkingslot
                    </div>
                    <input
                      label=" Enter polygons"
                      type="text"
                      className="form-control"
                      defaultValue={data?.addressId?.parking_slot}
                      value={editparking}
                      onChange={(e) => {
                        setEditParking(e.target.value);
                      }}
                    />
                  </div>
                  <div className="text-center ">
                  <div className="text-start">
                      Enter Pillarno
                    </div>
                    <input
                      type="text"
                      style={{
                        marginTop: 20,
                      }}
                      className="form-control"
                      defaultValue={data?.addressId?.pillar_no}
                      value={editPillar}
                      onChange={(e) => {
                        setEditPillar(e.target.value);
                      }}
                    />
                  </div>

                  <button
                    className="btn btn-block bg-primary"
                    style={{ color: "white", marginTop: 20 }}
                    onClick={() => handleUpdate()}
                  >
                    Update Adress Details
                  </button>
                </div>
              </Col>
            </Modal.Body>
          </Modal> */}
          <div className="d-flex align-items-center justify-content-between">
            <h5 style={{display: "flex", alignItems: "center"}}>
              <i className="mdi mdi-home me-1 mdi-24px"></i>Address Details
            </h5>
            <Badge
              onClick={() => setShowAddressModal(true)}
              bg="success"
              className="p-1"
              style={{fontSize: "13px", cursor: "pointer"}}
            >
              <i className="mdi mdi-square-edit-outline ">Edit</i>
            </Badge>
          </div>
          <div className="text-start mt-2 ms-3">
            {data?.addressId?.location_id?.cityId?.name ||
            data?.addressId?.city ? (
              <RenderView
                text="City"
                sub_text={
                  data?.addressId?.location_id?.cityId?.name ||
                  data?.addressId?.city
                }
              />
            ) : null}
            {data?.addressId?.address && (
              <RenderView text="Address" sub_text={data?.addressId?.address} />
            )}
            {data?.addressId?.address2 && (
              <RenderView
                text="Address 2"
                sub_text={data?.addressId?.address2}
              />
            )}
            {!_.isEmpty(data?.addressId?.loc_coordinates) && (
              <p
                className=" mb-1 font-13"
                style={{display: "flex", justifyContent: ""}}
              >
                <Col xl={5}>
                  <span style={{}}>Open in maps </span>
                </Col>
                <a
                  target="_blank"
                  href={`https://www.google.com/maps/search/?api=1&query=${data?.addressId?.loc_coordinates[1]}%2C${data?.addressId?.loc_coordinates[0]}`}
                >
                  <strong
                    style={{fontSize: 14, color: "#00000090"}}
                    className="ms-2 "
                  >
                    Google Maps
                  </strong>
                  <i className=" mdi mdi-arrow-top-right-thin-circle-outline ms-1"></i>
                </a>
              </p>
            )}

            {data?.addressId?.location_id?.name && (
              <RenderView
                text="Location"
                sub_text={data?.addressId?.location_id?.name}
              />
            )}
            {data?.addressId?.location_url && (
              <RenderView
                text="Location"
                sub_text={data?.addressId?.location_url}
              />
            )}

            {data?.addressId?.community_id?.name && (
              <RenderView
                text="Community"
                sub_text={data?.addressId?.community_id?.name}
              />
            )}
            {data?.addressId?.basement && (
              <RenderView
                text="Basement"
                sub_text={data?.addressId?.basement}
              />
            )}
            {data?.addressId?.block_id?.name && (
              <RenderView
                text="Block"
                sub_text={data?.addressId?.block_id?.name}
              />
            )}
            {data?.addressId?.parking_slot && (
              <RenderView
                text="Parking Slot"
                sub_text={data?.addressId?.parking_slot}
              />
            )}
            {data?.addressId?.pillar_no && (
              <RenderView
                text="Pillar no"
                sub_text={data?.addressId?.pillar_no}
              />
            )}
            {data?.addressId?.flat_no && (
              <RenderView text="Flat no" sub_text={data?.addressId?.flat_no} />
            )}
          </div>
        </Card.Body>
      </Card>
      <Card className="">
        <Card.Body>
          <div className="d-flex align-items-center justify-content-between">
            <h5 style={{display: "flex", alignItems: "center"}}>
              <i
                className={
                  data?.vehicle_type === "car"
                    ? "mdi mdi-car-convertible me-1 mdi-24px mt-0 mb-0"
                    : "mdi mdi-motorbike me-1 mdi-24px mt-0 mb-0"
                }
              ></i>
              Vehicle Details
            </h5>
            <Badge
              onClick={() => setShowVehicleModal(true)}
              bg="success"
              className="p-1"
              style={{fontSize: "13px", cursor: "pointer"}}
            >
              <i className="mdi mdi-square-edit-outline ">Edit</i>
            </Badge>
          </div>
          <div className="text-start mt-2 ms-3">
            <RenderView
              text={data?.vehicle_type === "car" ? "Car Brand" : "Bike Brand"}
              sub_text={data?.vehicleId?.brand_id?.name}
            />
            <RenderView
              text={data?.vehicle_type === "car" ? "Car Model" : "Bike Model"}
              sub_text={data?.vehicleId?.model_id?.name}
            />
            <RenderView
              text={"Vehicle Number"}
              sub_text={data?.vehicleId?.license_number}
            />
            {data?.vehicle_type === "car" ? (
              <RenderView
                text="Body type"
                sub_text={data?.vehicleId?.model_id?.car_body_type || "Bike"}
              />
            ) : null}
          </div>
        </Card.Body>
      </Card>

      {/* Vehicle Edit Modal */}
      <Modal show={showVehicleModal} onHide={() => setShowVehicleModal(false)}>
        <Modal.Header closeButton>
          <strong>Update Vehicle Number No.</strong>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="m-2">
              <Form.Label>Vehicle Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Vehicle Number..."
                value={vehicleLicenseNo || ""}
                onChange={(e) => setVehicleLicenseNo(e.target.value)}
              />
            </Col>
          </Row>
          <div align="end" className="m-2 mt-3" onClick={updateVehicleLicense}>
            <Button>Update Reg No.</Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Vehicle Update Address Modal  */}
      <Modal show={showAddressModal} onHide={() => setShowAddressModal(false)}>
        <Modal.Header closeButton>
          <strong>Update Address</strong>
        </Modal.Header>
        <Modal.Body>
          <Col className="m-2">
            <Form.Label>Parking Slot</Form.Label>
            <Form.Control
              type="text"
              placeholder="Parking Slot..."
              value={updateAddress?.parking_slot || ""}
              onChange={(e) =>
                setUpdateAddress({
                  ...updateAddress,
                  parking_slot: e.target.value,
                })
              }
            />
          </Col>
          <Col className="m-2">
            <Form.Label>Pillar No.</Form.Label>
            <Form.Control
              type="text"
              placeholder="Pillar No..."
              value={updateAddress?.pillar_no || ""}
              onChange={(e) =>
                setUpdateAddress({
                  ...updateAddress,
                  pillar_no: e.target.value,
                })
              }
            />
          </Col>

          <div align="end" className="m-2 mt-3" onClick={submitUpdateAddress}>
            <Button>Update Address</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BookingDetailsRightCard
