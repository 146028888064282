// @flow
import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import callApi from "../../../utils/apiCaller";
import { FormInput } from "../../../components";
import _ from "lodash";
import CreatableSelect from "react-select/creatable";

const FilterServicePlaces = (props) => {
  const li = ["INACTIVE", "ACTIVE"];
  const newList = li?.map((item) => {
    return {
      value: item,
      label: item,
    };
  });
  const pl = ["Airport", "Community", "Corporate"];
  const places = pl.map((location) => {
    return {
      value: location,
      label: location,
    };
  });
  const [cleanerDataFromApi, setCleanerDataFromApi] = React.useState([]);
  const [selectedCleaner, setSelectedCleaner] = useState([]);
  const [cities, setCity] = React.useState([]);
  const [locationListByCity, setLocationListByCity] = React.useState([]);
  const [location, setLocation] = React.useState([]);
  const [cityId, setCityId] = React.useState(null);
  const [apartments, setApartments] = React.useState([]);
  const [apartmentsFromApi, setApartmentsFromApi] = React.useState([]);
  const [status, setStatus] = useState([]);
  const [statusList, setStatusList] = useState(newList);
  const [addingPlacetype, setAddingPlacetype] = useState([]);

  const fetchData = (num, search) => {
    const info = {
      pageNum: num,
    };
    if (search) {
      info.search = search;
    }

    callApi("partner/getactivepartners", "POST", info).then((res) => {
      if (res.data) {
        setCleanerDataFromApi(res.data);
        const list = res?.data?.map((item) => {
          return {
            value: `${item.name} - ${item.phone}`,
            label: `${item.name} - ${item.phone}`,
            _id: item._id,
          };
        });
        setCleanerDataFromApi(list);
      }
    });
  };

  useEffect(() => {
    getCities();
    // fetchData(1);
    getAllApartments();
  }, []);

  const getCities = async () => {
    callApi(`city`, `get`).then((res) => {
      if (res.success) {
        setCity(res.data);
      }
    });
  };

  const getApartments = async (value) => {
    const location = value.map((i) => {
      return i._id;
    });

    callApi(`apartment`, `POST`, { locationId: location }).then((res) => {
      if (res.success) {
        const list_b = res.data.map((location) => {
          return {
            label: location.name,
            value: location.name,
            _id: location._id,
          };
        });
        setApartmentsFromApi(list_b);
      }
    });
  };
  const getAllApartments = async (value) => {
    const data = {};
    if (value) {
      data.search = value;
    }
    callApi(`apartments/getall`, `POST`, data).then((res) => {
      if (res.success) {
        const list_b = res.data.map((location) => {
          return {
            label: location.name,
            value: location.name,
            _id: location._id,
          };
        });
        setApartmentsFromApi(list_b);
      }
    });
  };

  const getLocationListByCity = async (value) => {
    callApi(`location/${value}`, `get`).then((res) => {
      if (res.success) {
        const list_b = res.data.map((location) => {
          return {
            label: location.name,
            value: location.name,
            _id: location._id,
          };
        });
        setLocationListByCity(list_b);
      }
    });
  };

  const applyFilters = (e) => {
    let filterObject = {};
    if (cityId) {
      filterObject.cityId = cityId;
    }
    if (!_.isEmpty(location)) {
      filterObject.locationId = location.map((i) => {
        return i._id;
      });
    }
    if (!_.isEmpty(apartments)) {
      filterObject.servicePlaceNameId = apartments.map((i) => {
        return i._id;
      });
    }
    if (!_.isEmpty(addingPlacetype)) {
      filterObject.addingPlacetype = addingPlacetype.value;
    }
    if (!_.isEmpty(status)) {
      filterObject.status = status.value;
    }
    props.applyAllFilters(filterObject);
  };

  return (
    <>
      <>
        <Row>
          <Col md={6} className="mb-2"></Col>
        </Row>
        <Row>
          <Col md={4} className="mb-2 mt-1">
            <FormInput
              style={{
                marginTop: 2,
              }}
              labelClassName="fw-bold"
              label="City"
              type="select"
              name="city"
              placeholder="Enter password"
              key="city"
              defaultValue={"select city"}
              onChange={(e) => {
                getLocationListByCity(e.target.value);
                setCityId(e.target.value);
                setLocation([]);
              }}
            >
              <option key="City" value="City">
                --select city--
              </option>
              {cities.map((city) => (
                <option key={city.id} value={city._id} _id={city._id}>
                  {city.name}
                </option>
              ))}
            </FormInput>
          </Col>
          <Col md={4} className="mb-2">
            <p className="fw-bold">Location</p>
            <Select
              isMulti={true}
              options={locationListByCity}
              className="react-select"
              classNamePrefix="react-select"
              value={location}
              onChange={(e) => {
                setLocation(e);
                setApartments([]);
                getApartments(e);
              }}
              placeholder="Select Services"
            ></Select>
          </Col>
          <Col md={4} className="mb-2">
            <p className="fw-bold">Select Service Place Type</p>
            <Select
              // isMulti={true}
              options={places}
              className="react-select"
              classNamePrefix="react-select"
              value={addingPlacetype}
              onChange={(e) => {
                setAddingPlacetype(e);
              }}
              placeholder="Select Service Place"
            ></Select>
          </Col>
          <Col md={4} className="mb-2">
            <p className="fw-bold">Select Status</p>
            <Select
              isClearable
              options={statusList}
              className="react-select"
              classNamePrefix="react-select"
              value={status}
              onChange={(e) => {
                setStatus(e);
              }}
              placeholder="Select Status"
            ></Select>
          </Col>
        </Row>
      </>
      <div className="text-end mt-2">
        <button
          className="btn btn-bloc "
          style={{ backgroundColor: "#2A882A", color: "white" }}
          onClick={() => applyFilters()}
        >
          <i className="mdi dripicons-checkmark mr-1"></i> Apply selected
          filters
        </button>
      </div>
    </>
  );
};

export default FilterServicePlaces;
