import React, {useCallback, useEffect, useState} from "react"
import {Button, Modal} from "react-bootstrap"
import Select from "react-select"
import callApi from "../../../utils/apiCaller"
import {debounce, get} from "lodash"
import callApiApp from "../../../utils/apiCallerApp"
import ImageUploadSingle from "../../../components/honc/ImageUploadSingle"
import "./CreateTicket.css"
import swal from "sweetalert"
import {useSelector} from "react-redux"

const CreateTicketInternal = ({model, setModel}) => {
  let {logged_in_user_details} = useSelector((state) => ({
    logged_in_user_details: state.AppState.logged_in_user_details,
  }))
  logged_in_user_details = JSON.parse(logged_in_user_details)
  const [state, setState] = React.useState({
    name: null,
    booking: null,
    issueType: null,
    issueDescription: "",
    issueImages: [],
  })
  console.log("logged_in_user_details------>", logged_in_user_details)
  const [userList, setUserList] = React.useState([])
  const [issueTypes, setIssueTypes] = React.useState([])
  const [bookings, setBookings] = React.useState([])
  const [userData, setUserData] = React.useState(null)
  const [bookingData, setBookingData] = React.useState(null)
  const [loading, setLoading] = useState(false)
  const [assignedUserData, setAssignedUserData] = useState([])
  const [errors, setErrors] = useState({})

  useEffect(() => {
    getAssignedUserData()
  }, [])

  const getAssignedUserData = () => {
    callApi(`getall`, "GET").then((res) => {
      console.log("res---->", res)
      if (res.success) {
        const list = res.data.map((item) => ({
          value: item._id,
          label: item.name,
        }))
        setAssignedUserData(list)
      }
    })
  }

  const searchCustomer = (e) => {
    if (!e) {
      return
    }
    let reqData = {
      pageNum: 1,
      pageSize: 20,
      search: e,
    }
    callApi("users", "POST", reqData).then((res) => {
      console.log("res----------->", res)
      if (res.data) {
        const list = res.data.map((item) => ({
          value: item._id,
          label: `${item.name ? item.name : "<No Name>"} - ${item.phone}`,
        }))
        setUserList(list)
      }
    })
  }

  const fetchUserList = useCallback(debounce(searchCustomer, 300), [])

  let getIssueType = (userId) => {
    const listOfIssues = [
      "Not satisfied with service",
      "Payment/Refund Issue",
      "Vehicle damage",
      "Vehicle number updation",
      "Community not found",
      "Technical",
      "Cashback",
      "App feedback",
    ]
    setIssueTypes(
      listOfIssues.map((item) => ({
        value: item,
        label: item,
      }))
    )
  }

  console.log("state---->", state)
  console.log("issueTypes-------->>>>>>", issueTypes)

  const selectStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? "lightgray" : "white",
      cursor: "pointer",
    }),
    option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      backgroundColor: isSelected
        ? "lightblue"
        : isFocused
        ? "lightgray"
        : null,
      color: isSelected ? "red" : "black", // Ensuring that selected option text is red in the dropdown
    }),
    singleValue: (styles, {isDisabled}) => ({
      ...styles,
      color: "#000", // Ensuring that selected value in the control is red
    }),
  }

  const getBookings = (userId) => {
    callApi(`users/getuserbookings/${userId}`, "GET").then((res) => {
      console.log("res----booking-details------>", res)
      if (res.success) {
        const list = res?.data?.dailyCleanings?.map((item) => ({
          value: item._id,
          label: `Daily car wash - ${item.vehicleNumber}`,
        }))
        // if ondemandBookings exist add them to the list
        if (res?.data?.ondemandBookings) {
          res?.data?.ondemandBookings.map((item) => {
            list.push({
              value: item._id,
              label: `On Demand - ${item.vehicleNumber}`,
            })
          })
        }
        setBookings(list)
      }
    })
  }

  const submitTicket = (e) => {
    setLoading(true)
    e.preventDefault()
    if (!validateForm()) {
      return
    }

    e.preventDefault()
    let reqData = {
      date: new Date(),
      message: state.issueDescription,
      ticketType: "internal",
      assigneeId: logged_in_user_details._id,
      assignedToId: state.assignedTo.value,
    }
    saveTicket(reqData)
  }

  const saveTicket = (reqData) => {
    console.log("reqData--->", reqData)
    setLoading(true)
    callApi("admin/tickets", "POST", reqData).then((res) => {
      console.log("res----create-------->", res)
      if (res.success) {
        swal("Success", "Ticket created successfully", "success")
        setModel && setModel(!model)
      } else {
      }
      setLoading(false)
    })
  }

  const getUserData = (userId) => {
    callApi(`users/details/${userId}`, "GET").then((res) => {
      if (res.success) {
        console.log("res---->", res)
        setUserData(res.data)
      }
    })
  }

  const getBookingData = (bookingId) => {
    callApi(`servicebooking/${bookingId}`, "GET").then((res) => {
      if (res.success) {
        console.log("res----booking-details------>", res)
        setBookingData(res.data)
      }
    })
  }

  const validateForm = () => {
    let newErrors = {}
    if (!state.assignedTo) newErrors.assignedTo = "Assigned To is required"
    if (!state.issueDescription || state.issueDescription.trim() === "")
      newErrors.issueDescription = "Issue Description is required"
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0 // Return true if no errors
  }

  return (
    <Modal
      show={model}
      onHide={() => {
        setModel && setModel(!model)
      }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Create a ticket by{" "}
          <span
            style={{
              color: "blue",
              textTransform: "capitalize",
            }}
          >
            {logged_in_user_details?.name}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="mb-3">
            <label
              htmlFor="exampleFormControlInput1"
              className="form-label mt-2"
            >
              Assigned To Agent*
            </label>
            <Select
              isClearable
              options={assignedUserData}
              className="react-select"
              classNamePrefix="react-select"
              value={state?.assignedTo}
              onChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  assignedTo: e ? e : null,
                }))
                setErrors({
                  ...errors,
                  assignedTo: e ? "" : "Assigned To is required",
                })
              }}
              placeholder="Select agent"
              styles={selectStyles}
            />
            {errors.assignedTo && (
              <div style={{color: "red"}}>{errors.assignedTo}</div>
            )}
            <label
              htmlFor="exampleFormControlInput1"
              className="form-label mt-2"
            >
              Issue Description*
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="6"
              placeholder="Enter issue description"
              value={state?.issueDescription}
              onChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  issueDescription: e.target.value,
                }))
                setErrors({
                  ...errors,
                  issueDescription:
                    e.target.value.trim() === ""
                      ? "Issue Description is required"
                      : "",
                })
              }}
            />
            {errors.issueDescription && (
              <div style={{color: "red"}}>{errors.issueDescription}</div>
            )}

            <div className="mt-3 text-center">
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => submitTicket(e)}
                disabled={loading}
              >
                Submit Ticket
              </Button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default CreateTicketInternal
