// @flow
import React from "react"
import {Row, Col, Card} from "react-bootstrap"
import {useLocation} from "react-router-dom"
import _ from "lodash"
import PageTitle from "../../components/PageTitle"
import callApi from "../../utils/apiCaller"
import callApiApp from "../../utils/apiCallerApp"
import TicketDetailsBox from "./components/TicketDetailBox"
import {useNavigate} from "react-router-dom"

const TicketDetails = (props) => {
  let navigate = useNavigate()
  const [ticketData, setTicketData] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [uploadedUrl, setUploadedUrl] = React.useState(null)
  const [bookingData, setBookingData] = React.useState({})
  const location = useLocation()
  const [state, setState] = React.useState({
    previousTickets: null,
    complaintData: [],
  })

  const fetchData = (ticketId) => {
    callApi(`admin/ticket/${ticketId}`, "GET").then((res) => {
      if (res.data) {
        window.scrollTo(0, 0)
        setTicketData(res.data)
        const data = res.data
        setIsLoading(false)
        getBookingDetails(data.customerId, data.bookingId)
        getPreviousTickets(data.customerId)
        getAllComplaints(data.customerId)
      }
    })
  }

  const getBookingDetails = (customerId, bookingId) => {
    callApiApp(
      `v2/customer/${customerId}/servicebooking/${bookingId}/get-booking`,
      "GET"
    ).then((res) => {
      if (res.data) {
        setBookingData(res.data)
      }
    })
  }

  const getAllComplaints = (customerId) => {
    const info = {
      userId: customerId,
    }
    callApi(`complaint/getall`, "post", info).then((res) => {
      console.log("res_______", res)
      if (res.success) {
        setState((prevState) => ({
          ...prevState,
          complaintData: res.data,
        }))
      } else {
      }
    })
  }

  const getPreviousTickets = (customerId) => {
    callApi(`admin/ticket/customer/${customerId}`, "POST").then((res) => {
      if (res.data) {
        setState((prevState) => ({
          ...prevState,
          previousTickets: res.data,
        }))
      }
    })
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    let id = location.search.replace("?", "")
    // remove date in serchparam
    if (!_.isEmpty(id)) {
      id = id.split("&")[0]
      id = id.replace("=", "")
    }
    const ticketId = props?.ticketId || location?.state?.ticketId || id
    console.log("location?.state?.ticketId-------", location?.state?.ticketId)
    console.log("props?.ticketId-------", props?.ticketId)
    console.log("ticketId-------", id)
    fetchData(ticketId)
  }, [])

  const updateStatus = (status, resolution, dataArray, resolutionImages) => {
    const id = location.search.replace("?", "")
    callApi(
      `admin/ticket/status/${
        props?.ticketId || location?.state?.ticketId || id
      }`,
      "POST",
      {
        status,
        comment: resolution,
        dataArray,
        resolutionImages,
      }
    ).then((res) => {
      if (res.data) {
        fetchData(props?.ticketId || location?.state?.ticketId || id)
        alert("Ticket status updated successfully")
        navigate(-1)
      }
    })
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          {label: "Open Tickets", path: "/support/open-tickets"},
          {label: "Profile", path: "/pages/profile", active: true},
        ]}
        title={"Ticket Details"}
      />

      <Row>
        <Col sm={12}>
          <button className="btn" type="button" onClick={() => navigate(-1)}>
            <i className="dripicons-arrow-thin-left" size="10x"></i>
            {"   "} Back
          </button>
          {ticketData && (
            <TicketDetailsBox
              ticketData={ticketData}
              updateStatus={updateStatus}
              uploadedUrl={uploadedUrl}
              bookingData={bookingData}
              previousTickets={state.previousTickets}
              getTicketData={(id) => {
                fetchData(id)
              }}
              complaintData={state.complaintData}
            />
          )}
        </Col>
        <Col xl={8}></Col>
      </Row>
    </>
  )
}

export default TicketDetails
