import React, {useState} from "react"
import {Row, Col, Card, Table, Modal} from "react-bootstrap"
import moment from "moment"
import HyperDatepicker from "../../../components/Datepicker"
import swal from "sweetalert"
import callApi from "../../../utils/apiCaller"
import StopSubscription from "../../../components/StopSubscription"
import ExtendSubscription from "../../../components/ExtendSubscription"

const BookingDetailsInDailyCleaning = (props) => {
  const data = props.data ? props.data : {}
  const [isEditMode, setIsEditMode] = React.useState(false)
  const [signInModal, setSignInModal] = useState(false)
  const [showStopSubscription, setShowStopSubscription] = useState(false)
  const [showExtendSubscription, setShowExtendSubscription] = useState(false)
  const [dateOfBirth, setDateOfBirth] = React.useState(
    new Date(data?.service_start_date || new Date())
  )

  const updateServiceDate = (date) => {
    console.log("date", date, moment(dateOfBirth).format("YYYY-MM-DD"))
    setSignInModal(!signInModal)
    if (data._id) {
      callApi(`servicebooking/reschedulebooking`, "POST", {
        servicebookingId: data._id,
        service_start_date: moment(dateOfBirth).format("YYYY-MM-DD"),
      }).then((res) => {
        console.log(res)
        if (res.success) {
          swal("Success", "Service date updated successfully", "success")
          props.getData(props.data._id)
        } else {
          swal("Error", res.message, "error")
        }
      })
    }
  }

  return (
    <Card>
      <Card.Body>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 className="mb-3">Booking details</h4>
          <div>
            <>
              {showStopSubscription && (
                <StopSubscription
                  data={data}
                  onHide={() => setShowStopSubscription(!showStopSubscription)}
                  getData={props.getData}
                />
              )}
              {showExtendSubscription && (
                <ExtendSubscription
                  data={data}
                  onHide={() => setShowStopSubscription(!showStopSubscription)}
                  getData={props.getData}
                />
              )}
              {data?.isTrial_booking && (
                <button
                  className="btn btn-primary btn-block me-3"
                  onClick={() => {
                    setShowExtendSubscription(!showExtendSubscription)
                  }}
                >
                  <i className="mdi mdi-arrow-expand "></i> Extend Booking
                </button>
              )}

              {data?.assigned_cleaner_id && !data?.isTrial_booking && (
                <>
                  {data.isCancelled ? null : (
                    <>
                      {data.isHold ? (
                        <button
                          className="btn btn-success btn-block "
                          onClick={() => {
                            setShowStopSubscription(!showStopSubscription)
                          }}
                        >
                          <i className="mdi mdi-account-check "></i> Activate
                          Subscription
                        </button>
                      ) : (
                        <>
                          {data.isCancelled ? null : (
                            <button
                              className="btn btn-danger btn-block"
                              onClick={() => {
                                setShowStopSubscription(!showStopSubscription)
                              }}
                            >
                              <i className="mdi mdi-account-remove mr-1"></i>
                              Stop Subscription
                            </button>
                          )}
                           {data?.isCancelled ? null : (
                          <i
                            className="mdi mdi-square-edit-outline btn btn-success"
                            style={{marginLeft: "20px"}}
                          >
                            {" "}
                            Reschedule
                          </i>
                        )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {/* <button
                style={{marginLeft: "20px"}}
                className="btn btn-success btn-block "
                onClick={() => props.handleCalenderView(true)}
              >
                <i className="mdi mdi-calendar-check"></i> View Calendar
              </button> */}
            </>
          </div>
        </div>
        <Row>
          <Col xl={6}>
            <div className="border p-3 mt-4 mt-lg-0 rounded">
              <Table responsive className="mb-0">
                <tbody>
                  <tr>
                    <td>Package amount :</td>
                    <td>
                      <i className="mdi mdi-currency-inr"></i>
                      <strong>{data?.booking_amount}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Plan :
                      <div
                        style={{
                          fontSize: "12px",
                          color: "red",
                        }}
                      >
                        {!data?.isTrial_booking
                          ? `${data?.quantity || 1} ${
                              data?.quantity > 1 ? "Months" : "Month"
                            } * ${data?.booking_amount}`
                          : "Free Trial"}
                      </div>
                    </td>
                    <td>
                      <i className="mdi mdi-currency-inr"></i>
                      <strong>{data?.actual_cost}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Discount </span>
                      <span className="text-red" style={{color: "red"}}>
                        ({data?.discount_percentage}%):
                      </span>
                    </td>
                    <td>
                      <i className="mdi mdi-currency-inr"></i>
                      <strong className="text-red" style={{color: "red"}}>
                        -{data?.discount_amount}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Total amount :</strong>
                    </td>
                    <td>
                      <i className="mdi mdi-currency-inr"></i>
                      <strong
                        className="text-red text-uppercase"
                        style={{fontSize: "16px", color: "black"}}
                      >
                        {data?.booking_cost}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        Autodebit(
                        <span
                          className={
                            !data?.customerId?.autoDebitStatus ||
                            data?.customerId?.autoDebitStatus === "Inactive"
                              ? "text-danger"
                              : "text-success"
                          }
                        >
                          {data?.customerId?.autoDebitStatus}
                        </span>
                        ) :
                      </strong>
                    </td>
                    <td>
                      <i className="mdi mdi-currency-inr"></i>
                      <strong className="text-red text-uppercase">
                        <span className={`ml-2`}>
                          {data?.customerId?.autoDebitStatus === "Active"
                            ? data?.customerId?.max_autodebit_amount
                            : "--NA--"}
                        </span>
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    {/* <td colSpan="2">
        <div className="d-flex justify-content-center">
  <Card className={`p-2 mt-2 ${data?.customerId.autoDebitStatus == 'Active' ? 'bg-success' : 'bg-danger'}`}>
    <span className="ml-2" style={{color:"white"}}>
      {data?.customerId?.autoDebitStatus == 'Active'?`Autodebit = ₹ ${data?.customerId?.max_autodebit_amount} ` : "Autodebit : NA"}
    </span>
  </Card>
</div>

</td> */}
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>

          {/* <Col xl={6}>
            <div className="table-responsive">
              <table className="table mb-0">
                <tbody>
                  <tr>
                    <td>Service Name</td>
                    <td>{data?.servicepackageId?.service_name}</td>
                  </tr>
                  <tr>
                    <td>Booking Number</td>
                    <td>{data?.booking_num}</td>
                  </tr>
                  <tr>
                    <td>Service Booking Date</td>
                    <td>
                      {moment(data?.service_booking_date).format(
                        "DD MMM, YYYY"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Service Start Date</td>
                    <td>
                      <div onClick={() => setSignInModal(!signInModal)}>
                        {moment(data?.service_start_date).format(
                          "DD MMM, YYYY"
                        )}
                       
                      </div>
                    </td>
                  </tr>
                  {data?.isCancelled && (
                    <tr>
                      <td>Cancelled Date</td>
                      <td>
                        {moment(data?.cancelledOn).format("DD MMM, YYYY")}
                      </td>
                    </tr>
                  )}
                  {data?.isCancelled && (
                    <tr>
                      <td>Cancelled Reason</td>
                      <td>{data?.cancellation_reason}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Col> */}
        </Row>

        <Modal show={signInModal} onHide={() => setSignInModal(!signInModal)}>
          <Modal.Body>
            <div className="text-center mt-2 mb-4">
              <h4>Update Service Date</h4>
              <div className="text-center">
                <HyperDatepicker
                  hideAddon={true}
                  value={dateOfBirth}
                  onChange={(date) => {
                    setDateOfBirth(date)
                  }}
                />
              </div>
            </div>
            <div className="mb-3 text-center mt-4">
              <button
                onClick={() => {
                  updateServiceDate()
                }}
                className="btn btn-rounded btn-primary"
              >
                Update
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {isEditMode && (
          <div className="text-end">
            <button
              className="btn btn-danger mt-2"
              style={{marginRight: "20px"}}
              onClick={() => setIsEditMode(false)}
            >
              <i className="mdi mdi-content-save"></i>Cancel
            </button>
            <button className="btn btn-success mt-2 pl-2 " onClick={() => {}}>
              <i className="mdi mdi-content-save"></i> Save
            </button>
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default BookingDetailsInDailyCleaning
